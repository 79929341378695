import { getLogger } from '@vf/services/LoggingService';
import { ContactFlow, ContactFlowModule, PhoneNumberSummary, PromptSummary } from '@aws-sdk/client-connect';
import { HelperApi, ConnectQueue, GetPromptAudioRequest } from '@vf-omp/shared';
import { ApiClientBase } from './ApiClientBase';
import { Voice } from '@aws-sdk/client-polly';

export class HelpersApiClient extends ApiClientBase implements HelperApi {
  pollyVoicesPerLang = new Map<string, Voice[]>();
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('HelpersApiClient'), useAuth);
    //pre-populate Polly voices for English and Spanish
    this.getListOfPollyVoices('en-US');
    this.getListOfPollyVoices('es-US');
  }

  async getAllQueues(): Promise<ConnectQueue[]> {
    const result = await this.get('/api/helpers/queues');
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }

  async getAgentQueues(): Promise<ConnectQueue[]> {
    const result = await this.get('/api/helpers/agents');
    return result.json();
  }

  async getAllFlows(): Promise<ContactFlow[]> {
    const result = await this.get('/api/helpers/flows');
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }

  async getAllModules(): Promise<ContactFlowModule[]> {
    const result = await this.get('/api/helpers/modules');
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }

  async getAllPrompts(): Promise<PromptSummary[]> {
    const result = await this.get('/api/helpers/prompts');
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }

  async getAllNumbers(): Promise<PhoneNumberSummary[]> {
    const result = await this.get('/api/helpers/numbers');
    return await result.json();
  }

  async getAllTimezones(): Promise<string[]> {
    const result = await this.get('/api/helpers/timezones');
    return await result.json();
  }

  async getPromptAudio(body: GetPromptAudioRequest): Promise<{ Audio: Buffer }> {
    const result = await this.post('/api/helpers/getPromptAudio', body);
    const resultConv = await result.json();
    return resultConv;
  }

  async getListOfPollyVoices(lang: string): Promise<Voice[]> {
    if (!this.pollyVoicesPerLang.has(lang)) {
      const result = await this.get(`/api/helpers/getPollyVoices/${lang}`);
      const resultJson = await result.json();
      this.pollyVoicesPerLang.set(lang, resultJson);
    }
    return this.pollyVoicesPerLang.get(lang) || [];
  }
}
